import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";

Vue.use(Router);

export default new Router({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/about",
      name: "about",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/About.vue")
    },
    {
      path: "/Admin/product-settings/define-products",
      name: "defineProducts",
      component: () => import("./views/admin/DefineProducts.vue")
    },
    {
      path: "/Admin/product-settings/loan-product-settings",
      name: "loanProductSettings",
      component: () => import("./views/admin/LoanProductSettings.vue")
    },
    {
      path: "/Admin/account-settings/define-chart-of-accounts-coa",
      name: "coa",
      component: () => import("./views/admin/Coa.vue")
    }
  ]
});
