import Vuex from "vuex";
import Vue from "vue";
import customers from "./modules/customers";
import branches from "./modules/branches";
import products from "./modules/products";
import fxCharts from "./modules/fxCharts";

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    customers,
    branches,
    products,
    fxCharts
  }
});
