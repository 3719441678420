import axios from "axios";

const state = {
  fxCharts: [],
  productDefinition: [],
  activeProduct: null
};

const getters = {
  allFxCharts: state => state.fxCharts
};

const actions = {
  async fetchAllFxCharts({ commit }) {
    const response = await axios.get("/api/fx-charts");

    commit("setAllFxCharts", response.data);
  }
};

const mutations = {
  setAllFxCharts: (state, fxCharts) => (state.fxCharts = fxCharts)
};

export default {
  state,
  getters,
  actions,
  mutations
};
