<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style lang="scss">
#app label {
  color: #000;
}
</style>
<script>
import { mapGetters, mapActions } from "vuex";
import CoaForm from "./views/admin/CoaForm.vue";
export default {
  name: "App",
  methods: {
    ...mapActions(["fetchAll", "fetchLoginBranch"])
  },
  computed: mapGetters(["branches"]),
  created() {
    this.fetchAll();
    //console.log(this.getBranch());
  }
};
</script>

