import axios from "axios";

const state = {
  customer: {}
};

const getters = {
  getCustomer: state => state.customer
};

const actions = {
  async fetchCustomer({ commit }, params) {
    const response = await axios.get("/api/customers", params);

    commit("setCustomer", response.data);
  },
  async addCustomer({ commit }, customer) {
    const response = await axios.post("/api/customers", customer);

    commit("newCustomer", response.data);
  },
  async updateCustomer({ commit }, customer) {
    const response = await axios.put("/api/customers", customer);

    commit("newCustomer", response.data);
  }
};

const mutations = {
  setCustomer: (state, customer) => (state.customer = customer),
  newCustomer: (state, customer) => (state.customer = customer),
  updateCustomer: (state, customer) => (state.customer = customer)
};

export default {
  state,
  getters,
  actions,
  mutations
};
