import axios from "axios";

import coa from "./plainCoa";
const state = {
  products: [],
  productDefinition: [],
  activeProduct: null,
  plainCoa: coa
};

var token = $("meta[name='_csrf']").attr("content");
var header = $("meta[name='_csrf_header']").attr("content");

// var options = {
//   headers: {
//     header: token
//   }
// };
axios.defaults.headers.common[header] = token;
const getters = {
  products: state => state.products,
  productDefinition: state => state.productDefinition,
  activeProduct: state => state.activeProduct,
  loanProducts: state =>
    (state.products = state.products.filter(product => product.product == "L")),
  savingProducts: state =>
    (state.products = state.products.filter(product => product.product == "S")),
  productHas: (state, glNo) =>
    (state.products = state.products.filter(product => product.glNo == glNo)),
  coaTree: state => {
    var list = [];
    state.products.forEach((c, k) => {
      list.push({
        index: k,
        id: c.id,
        text: c.glNo + " - " + c.acName,
        acName: c.acName,
        acType: c.acType,
        value: c.glNo,
        fxCrncy: c.fxCrncy,
        glNo: c.glNo,
        fsKey: c.fsKey,
        parent: c.parent,
        lavel: c.lavel,
        canDelete: c.canDelete,
        canAddChild: c.canAddChild,
        noOfAccounts: c.noOfAccounts,
        child: c.child,
        product: c.product,
        icon: "fa fa-money",
        opened: false
      });
    });

    var map = {},
      node,
      roots = [],
      i;
    for (i = 0; i < list.length; i += 1) {
      map[list[i].fsKey] = i; // initialize the map
      list[i].children = []; // initialize the children
    }
    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.parent !== "0000") {
        // if you have dangling branches check that map[node.parentId] exists
        list[map[node.parent]].children.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  }
};

const actions = {
  async fetchAllProducts({ commit }) {
    const response = await axios.get("/api/coas/settings");

    commit("setProducts", response.data);
  },
  async fetchProductDefinition({ commit }) {
    const response = await axios.get("/api/coas/define-product");

    commit("setProductDefinition", response.data);
  },
  async fetchProducts({ commit }, params) {
    const response = await axios.get("/api/coas", params);
    commit("setProducts", response.data);
  },
  setActiveProduct({ commit }, params) {
    commit("setActiveProduct", params);
  },
  async addCoa({ commit }, params) {
    var coa = JSON.parse(JSON.stringify(state.plainCoa)).coa;
    coa.parent = state.activeProduct.parent;
    coa.glNo = state.activeProduct.glNo;
    coa.acName = state.activeProduct.acName;
    coa.lavel = state.activeProduct.lavel;
    coa.acType = state.activeProduct.acType;
    coa.product = state.activeProduct.product;

    const response = await axios.post("/api/coas", coa);
    return response;
    //commit("addCoa", params);
  },
  async updateCoa({ commit }, params) {
    var coa = JSON.parse(JSON.stringify(state.plainCoa)).coa;

    coa.acName = state.activeProduct.acName;
    coa.product = state.activeProduct.product;
    coa.glNo = state.activeProduct.glNo;
    coa.id = state.activeProduct.id;
    coa.simpleUpdate=true;

    const response = await axios.put("/api/coas", coa);
    return response;
  },
  async deleteCoa({ commit }) {
    return await axios.delete("/api/coas/" + state.activeProduct.id);
  }
};

const mutations = {
  setProducts: (state, products) => (state.products = products),
  setProductDefinition: (state, productDefinition) =>
    (state.productDefinition = productDefinition),
  setActiveProduct: (state, activeProduct) =>
    (state.activeProduct = activeProduct)
};

export default {
  state,
  getters,
  actions,
  mutations
};
