import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/styles/scripts/index";
import VJstree from "vue-jstree";

Vue.config.productionTip = false;
Vue.use(VJstree);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#scaleApp");
