import axios from "axios";

const state = {
  branches: [],
  loginBranch: {}
};

const getters = {
  branches: state => state.branches
};

const actions = {
  async fetchAll({ commit }) {
    const response = await axios.get("/api/companies");

    commit("setBraches", response.data);
  },
  async fetch({ commit }, params) {
    const response = await axios.get("/api/companies", params);
    commit("setBraches", response.data);
  },
  async fetchLoginBranch({ commit }) {
    const response = await axios.get("/api/companies/login");
    commit("setLoginBranch", response.data);
  }
};

const mutations = {
  setBraches: (state, branches) => (state.branches = branches),
  setLoginBranch: (state, loginBranch) => (state.loginBranch = loginBranch)
};

export default {
  state,
  getters,
  actions,
  mutations
};
