var coa = {
  glNo: "",
  acName: "",
  acType: "",
  product: " ",
  glNo: "",
  acName: "",
  acType: "",
  product: " ",
  lncglNo: "            ",
  lncacNo: "      ",
  lniglNo: "            ",
  lniacNo: "      ",
  lnarate: 0.0,
  lnaratec: false,
  lnperiod: 0.0,
  lnperiodc: false,
  lninsttype: " ",
  lninsttypc: false,
  lnintcal: "  ",
  lnintcalc: false,
  sidrGl: "            ",
  sidrAc: "      ",
  siminBal: 0.0,
  siarate: 0.0,
  sitrxCode: "   ",
  lfcrGl: "            ",
  lfcrAc: "      ",
  lffee: 0.0,
  lftrxCode: "   ",
  stcrGl: "            ",
  stcrAc: "      ",
  stfee: 0.0,
  sttrxCode: "   ",
  smcrGl: "            ",
  smcrAc: "      ",
  smfee: 0.0,
  smtrxCode: "   ",
  smminBal: 0.0,
  dormant: 9999.0,
  tdrGl: "            ",
  tdrAc: "            ",
  penGlNo: "      ",
  penAcNo: "      ",
  provCglno: "      ",
  provDglno: "      ",
  woffDglno: "      ",
  provCacno: "      ",
  provDacno: "      ",
  woffDacno: "      ",
  fxCrncy: "TSH",
  wdcomm: 0.0,
  wdcommgl: "      ",
  wdcommac: "      ",
  closeLoan: 2.0,
  graceLoan: 2.0,
  secondloan: false,
  sintCal: 1.0,
  intPeriod: 1.0,
  withhTax: 0.0,
  wtaxGlno: "      ",
  wtaxAcno: "      ",
  fsKey: "",
  parent: "",
  lavel: 1.0,
  header: false,
  langId: 0.0,
  swcPer: 0.0,
  penCal: 1.0,
  penArr: 1.0,
  penAmt: 0.0,
  penPer: 0.0,
  penGp: 0.0,
  penEday: 9999.0,
  lcommGl: "      ",
  lcommAc: "      ",
  lcommPe: 0.0,
  lcommFa: 0.0,
  linsuGl: "      ",
  linsuAc: "      ",
  linsuPe: 0.0,
  linsuFa: 0.0,
  lappGl: "      ",
  lappAc: "      ",
  lappPe: 0.0,
  lappFa: 0.0,
  lcommDd: false,
  linsuDd: false,
  lappDd: false,
  intuhalfp: false,
  equalinst: false,
  periodsav: 0.0,
  receipt: false,
  periodsp: 0.0,
  accrudGl: "      ",
  accrudAc: "      ",
  accrucGl: "      ",
  accrucAc: "      ",
  loanLmt: 9999999.0,
  flatiamt: 0.0,
  flatiamc: false,
  duedate: 0.0,
  figlNo: "      ",
  fiacNo: "      ",
  fpglNo: "      ",
  fpacNo: "      ",
  fpflat: 0.0,
  fprate: 0.0,
  fminamt: 0.0,
  fmaxamt: 0.0,
  fminprd: 0.0,
  fmaxprd: 0.0,
  fintrate: 0.0,
  dueeom: false,
  prispay: 0.0,
  grace: 0.0,
  gracec: false,
  minlnamt: 0.0,
  strxper: 0.0,
  intspay: 0.0,
  busType: 0.0,
  bustcomp: false,
  gracwint: 0.0,
  soglNo: "      ",
  soacNo: "      ",
  soaRate: 0.0,
  soiCal: 1.0,
  wdcommet: true,
  intDays: 365.0,
  intDayo: 1.0,
  lcmtGl: "      ",
  lcmtAc: "      ",
  lcmtPe: 0.0,
  lcmtFa: 0.0,
  lcmtDd: false,
  penCalt: 0.0,
  fdVat: 0.0,
  fdVatg: "      ",
  fdVata: "      ",
  woffCglno: "      ",
  woffCacno: "      ",
  ldfInd: 0.0,
  ldfGrp: 0.0,
  ldfGrm: 0.0,
  ldfBus: 0.0,
  ldfGl: "      ",
  ldfAc: "      ",
  fdFreq: "M",
  provrt0: 0.0,
  provrt1: 0.0,
  provrt2: 0.0,
  provrt3: 0.0,
  provrt4: 0.0,
  provrt5: 0.0,
  provrt6: 0.0,
  lwdcomo: false,
  diviCrgl: "100100",
  diviCrac: "      ",
  fint12m: false,
  photoreq: false,
  flataout: false,
  provrt7: 0.0,
  provrt8: 0.0,
  provrt9: 0.0,
  provrt10: 0.0,
  provrt11: 0.0,
  provrt12: 0.0,
  odDrgl: "      ",
  odDrac: "      ",
  odCrgl: "      ",
  odCrac: "      ",
  recnoSha: false,
  curShaval: 0.0,
  provrtol: 0.0
};

export default {
  coa
};
